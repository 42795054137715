import styled, { css } from "styled-components";

/** srOnlyStyles: Screen reader only styles
 * These styles visually hide text while still making it accessible to screen readers.
 * By setting the text color to transparent and positioning it off-screen,
 * it remains available for users relying on screen readers. */

export const srOnlyStyles = css`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
`;

/** Span with screen reader only styles applied */
export const ScreenReaderOnly = styled.span`
  ${srOnlyStyles}
  height: 0;
  width: 0;
  flex: none;
`;
