import { OrgRole } from "@hex/common";
import React, { useMemo } from "react";

import { HexTooltip } from "../../../hex-components/HexTooltip.js";
import { useCurrentUser } from "../../../hooks/me/useCurrentUser.js";
import { useTerminology } from "../../../hooks/useTerminology.js";
import { TabView } from "../../../route/routes.js";
import { CyData } from "../../../util/cypress.js";

import QuickFilterSelect from "./QuickFilterSelect.js";
interface SelectTabViewProps {
  onChange: (value: TabView) => void;
  value: TabView;
  resourceName: string;
}

export enum HomeTabView {
  ALL = "all",
  YOU = "you",
  SHARED_WITH_YOU = "sharedWithYou",
  SHARED_WITH_ORG = "sharedWithOrg",
}

export const SelectTabView: React.ComponentType<SelectTabViewProps> = ({
  onChange,
  resourceName,
  value,
}) => {
  const currentUser = useCurrentUser();
  const { createdByYou } = useTerminology();
  const currentViewerIsGuest = currentUser?.orgRole === OrgRole.GUEST;

  const options: { title: string; value: TabView; id: string }[] =
    useMemo(() => {
      return [
        {
          title: `All ${resourceName}s`,
          value: HomeTabView.ALL,
          id: "all",
          dataCy: CyData.HOME_TAB_SELECT_OPTION_ALL,
        },
        {
          title: createdByYou,
          value: HomeTabView.YOU,
          id: "you",
          dataCy: CyData.HOME_TAB_SELECT_OPTION_CREATED_BY_YOU,
        },
        {
          title: "Shared with you",
          value: HomeTabView.SHARED_WITH_YOU,
          id: "shared-with-you",
          dataCy: CyData.HOME_TAB_SELECT_OPTION_SHARED_WITH_YOU,
        },
        {
          title: "Shared with workspace",
          value: HomeTabView.SHARED_WITH_ORG,
          id: "shared-with-org",
          dataCy: CyData.HOME_TAB_SELECT_OPTION_SHARED_WITH_ORG,
          disabled: currentViewerIsGuest,
          wrapper: ({ children }: { children: React.ReactChildren }) => (
            <HexTooltip
              content="You don't have permission to view these projects"
              disabled={!currentViewerIsGuest}
            >
              {children}
            </HexTooltip>
          ),
        },
      ];
    }, [currentViewerIsGuest, resourceName, createdByYou]);

  return (
    <QuickFilterSelect<TabView>
      dataCy={CyData.HOME_TAB_SELECT}
      minimal={false}
      options={options}
      value={value}
      onChange={onChange}
    />
  );
};
