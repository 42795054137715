import { CollectionOrder, HexOrder, OrderByDirection } from "@hex/common";
import { useMemo } from "react";

import { HomeQueryParamsKey, useQueryParams } from "../../route/routes.js";
import { ViewCountDuration } from "../hex-list/ViewCountDuration.js";

export interface SelectedHexSortOrderAndDirection {
  sortOrder: HexOrder;
  direction: OrderByDirection;
}

/** Returns the sort order and sort direction from the page's query params. If none is specified, defaults to "recently_viewed" and "DESC"*/
export function useSelectedSortHexOrder(
  defaultSelection?: SelectedHexSortOrderAndDirection,
): SelectedHexSortOrderAndDirection {
  const queryParams = useQueryParams();

  const selectedSortOrder = useMemo(() => {
    const sortParams = queryParams.get(HomeQueryParamsKey.SORT);
    if (sortParams != null) {
      const splitParams = sortParams.split(":");
      const sortOrder =
        splitParams.length > 0
          ? (splitParams[0].toUpperCase() as HexOrder)
          : defaultSelection?.sortOrder ?? HexOrder.RECENTLY_VIEWED;
      const direction =
        splitParams.length > 1
          ? (splitParams[1].toUpperCase() as OrderByDirection)
          : defaultSelection?.direction ?? "DESC";
      return {
        sortOrder,
        direction,
      };
    }
    return (
      defaultSelection ?? {
        direction: "DESC" as const,
        sortOrder: HexOrder.RECENTLY_VIEWED,
      }
    );
  }, [queryParams, defaultSelection]);

  return selectedSortOrder;
}

/** Returns the selected view count duration from the page's query params. If none is specified, defaults to "all_time" */
export function useSelectedViewCountDuration(): ViewCountDuration {
  const queryParams = useQueryParams();

  const selectedViewCountDuration = useMemo(() => {
    const durationParam = queryParams.get(
      HomeQueryParamsKey.VIEW_COUNT_DURATION,
    );
    return durationParam != null
      ? (durationParam.toUpperCase() as ViewCountDuration)
      : ViewCountDuration.ALL_TIME;
  }, [queryParams]);

  return selectedViewCountDuration;
}

/** Returns the sort order from the page's query params. If none is specified, defaults to "name" */
export function useSelectedSortCollectionOrder(): CollectionOrder {
  const queryParams = useQueryParams();

  const selectedSortOrder = useMemo(() => {
    const sortParam = queryParams.get(HomeQueryParamsKey.SORT);
    return sortParam != null
      ? (sortParam.toUpperCase() as CollectionOrder)
      : CollectionOrder.NAME;
  }, [queryParams]);

  return selectedSortOrder;
}
