export const STATIC_ICON = {
  ChevronExpand:
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="m8.007 1.828 4.646 4.309-.68.733-3.98-3.691-4.16 3.698-.665-.748 4.84-4.301ZM8 12.834 3.832 9.13l-.664.748L8 14.172l4.832-4.295-.664-.748L8 12.834Z" fill="red"/></svg>',
  SingleChevronRight:
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke-width="1"><g><path clip-rule="evenodd" d="M9.116 8 5.47 11.646l.707.708L10.53 8 6.177 3.646l-.707.708L9.116 8Z" fill="red" fill-rule="evenodd"></path></g></g></svg>',
  SingleChevronLeft:
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke-width="1"><g><path clip-rule="evenodd" d="m6.884 8 3.646-3.646-.707-.708L5.47 8l4.353 4.354.707-.708L6.884 8Z" fill="red" fill-rule="evenodd"></path></g></g></svg>',
  SingleChevronDown:
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke-width="1"><g><path clip-rule="evenodd" d="M8 9.116 4.354 5.47l-.708.707L8 10.53l4.354-4.353-.707-.707L8 9.116Z" fill="red" fill-rule="evenodd"></path></g></g></svg>',
  CrossSmall:
    '<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg"><g stroke-width="1"><g><path clip-rule="evenodd" d="m7.293 8.5-4.01-4.01.707-.708L8 7.792l4.01-4.01.707.708-4.01 4.01 4.01 4.01-.707.707L8 9.207l-4.01 4.01-.708-.707 4.01-4.01Z" fill="red" fill-rule="evenodd"></path></g></g></svg>',
};
