import { Position } from "@blueprintjs/core";
import { ItemListRenderer, ItemRenderer, Select } from "@blueprintjs/select";
import React, { useCallback, useMemo } from "react";

import {
  HexButton,
  HexMenu,
  HexMenuDivider,
  HexMenuItem,
} from "../../../hex-components";
import { TickIcon } from "../../icons/CustomIcons";

const POPOVER_PROPS = {
  minimal: true,
  position: Position.BOTTOM_RIGHT,
  rootBoundary: "viewport" as const,
  hasBackdrop: true,
  autoFocus: false,
  canEscapeKeyClose: true,
};

export interface QuickFilterSelectOption<T> {
  disabled?: boolean;
  dataCy?: string;
  labelSuffix?: React.FC;
  title: string;
  value: T;
  wrapper?: React.FC;
}

export interface QuickFilterSelectProps<T> {
  dataCy?: string;
  buttonIcon?: JSX.Element;
  buttonText?: string;
  label?: string;
  options: QuickFilterSelectOption<T>[];
  value: QuickFilterSelectOption<T>["value"];
  onChange: (value: QuickFilterSelectOption<T>["value"]) => void;
  minimal?: boolean;
  getButtonText?: (currentSelection: QuickFilterSelectOption<T>) => string;
  subtle?: boolean;
  small?: boolean;
  extraSmall?: boolean;
  className?: string;
}

function DEFAULT_GET_BTN_TEXT<Value>({
  title,
}: QuickFilterSelectOption<Value>): QuickFilterSelectOption<Value>["title"] {
  return title;
}

function QuickFilterSelect<Value>({
  buttonIcon,
  className,
  dataCy,
  extraSmall = false,
  getButtonText = DEFAULT_GET_BTN_TEXT<Value>,
  label,
  minimal = true,
  onChange,
  options,
  small = false,
  subtle = true,
  value,
}: QuickFilterSelectProps<Value>): ReturnType<
  React.FunctionComponent<QuickFilterSelectProps<Value>>
> {
  const currentSelection = useMemo(
    () =>
      options.find(({ value: optionValue }) => optionValue === value) ||
      options[0],
    [value, options],
  );

  const currentButtonText = getButtonText(currentSelection);

  const handleSelectItem = useCallback(
    ({ value: newValue }) => {
      onChange(newValue);
    },
    [onChange],
  );

  const itemListRenderer: ItemListRenderer<
    QuickFilterSelectOption<typeof value>
  > = useCallback(
    ({ items, renderItem }) => {
      return (
        <HexMenu css=" display: flex; flex-direction: column;">
          {label ? <HexMenuDivider title={label} /> : null}
          {items.map(renderItem)}
        </HexMenu>
      );
    },
    [label],
  );

  const itemRenderer: ItemRenderer<QuickFilterSelectOption<typeof value>> =
    useCallback(
      (item, { handleClick, modifiers: { active: keyboardActive } }) => {
        const {
          dataCy: itemDataCy,
          disabled,
          labelSuffix: LabelSuffix,
          wrapper,
        } = item;

        const LabelElement = LabelSuffix != null ? <LabelSuffix /> : null;

        const Wrapper = wrapper != null ? wrapper : React.Fragment;

        return (
          <Wrapper key={item.value as React.Key}>
            <HexMenuItem
              $keyboardActive={keyboardActive}
              active={item.value === currentSelection.value}
              css="min-width: 160px;"
              data-cy={itemDataCy}
              disabled={disabled}
              labelElement={
                item.value === currentSelection.value ? (
                  <TickIcon />
                ) : (
                  LabelElement
                )
              }
              text={item.title}
              onClick={handleClick}
            />
          </Wrapper>
        );
      },
      [currentSelection.value],
    );

  return (
    <Select<QuickFilterSelectOption<typeof value>>
      filterable={false}
      itemListRenderer={itemListRenderer}
      itemRenderer={itemRenderer}
      items={options}
      popoverProps={POPOVER_PROPS}
      onItemSelect={handleSelectItem}
    >
      <HexButton
        className={className}
        data-cy={dataCy}
        extraSmall={extraSmall}
        icon={buttonIcon}
        minimal={minimal}
        small={small}
        subtle={subtle}
      >
        {currentButtonText}
      </HexButton>
    </Select>
  );
}

export default React.memo(QuickFilterSelect) as typeof QuickFilterSelect;
